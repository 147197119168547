<template>
	<div>
		<main-header></main-header>
		<div class="flex-container--col--center">
			<h1 class="page-title">비밀번호 재설정</h1>
		</div>
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';

export default {
	components: { MainHeader, Footer },
	data() {
		return {};
	},
	methods: {},
};
</script>

<style></style>
